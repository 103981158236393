<template>
  <div class="questionnaire">
    <div class="steps" :style="{width:steps*33+'%'}"></div>
    <!-- <van-form @submit="firstStep" v-show="steps == 1">
      <h3>01 健康信息 </h3>
      <p class="question">1.医生是否曾经说过您的<span class="blue">心脏有些问题</span>，需要按照医生建议进行活动?</p>
      <van-field name="q1" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[0].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">2.进行<span class="blue">运动时</span>您是否有<span class="blue">胸口疼痛</span>的情况?</p>
      <van-field name="q2" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[1].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">3. 在过去的一个月里，您是否在<span class="blue">没有运动时也会感到胸口疼痛</span>？</p>
      <van-field name="q3" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[2].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">4. 您是否曾因<span class="blue">感到眩晕而失去平衡，或失去知觉</span>？</p>
      <van-field name="q4" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[3].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">5. 您是否有骨骼或关节方面的问题，会<span class="blue">因运动而加重</span>？</p>
      <van-field name="q5" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[4].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">6.您现在是否有服用医生所开立的<span class="blue">改善血压或心脏方面的问题的药物</span>？</p>
      <van-field name="q6" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[5].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">7. 您是否有其他上述未提及的理由<span class="blue">令您无法运动，或运动中感到不适</span>？</p>
      <van-field name="q7" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[6].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">8. 目前，您哪侧膝关节感到不适？</p>
      <van-field name="q8" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[7].value" direction="horizontal">
            <van-radio name="左侧">左侧</van-radio>
            <van-radio name="右侧">右侧</van-radio>
            <van-radio name="双侧">双侧</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">9. 您是否有膝关节X片或核磁诊断证明？</p>
      <van-field name="q9" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[8].value" direction="horizontal">
            <van-radio name="有">有</van-radio>
            <van-radio name="没有">没有</van-radio>
          </van-radio-group>
          <van-field v-model="otherQuestion.other1" placeholder="请填写诊断结果：" v-if="formData.questionsValues[8].value == '有'" />
        </template>
      </van-field>
      <p class="question">10. 膝关节不适给您带来的主要困扰是？（多选）</p>
      <van-field name="q10" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-checkbox-group v-model="formData.questionsValues[9].value" direction="horizontal">
            <van-checkbox name="疼痛">疼痛</van-checkbox>
            <van-checkbox name="酸胀不适">酸胀不适</van-checkbox>
            <van-checkbox name="关节无力">关节无力</van-checkbox>
            <van-checkbox name="关节角度受限制">关节角度受限制</van-checkbox>
            <van-checkbox name="日常生活受限制（走路、上下楼等）">日常生活受限制（走路、上下楼等）</van-checkbox>
            <van-checkbox name="体育运动受影响">体育运动受影响</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <p class="question">11. 您开始出现膝关节问题多长时间了？</p>
      <van-field name="q11" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[10].value" direction="horizontal">
            <van-radio name="1个月内">1个月内</van-radio>
            <van-radio name="3个月内">3个月内</van-radio>
            <van-radio name="3~6个月">3~6个月</van-radio>
            <van-radio name="6~12个月">6~12个月</van-radio>
            <van-radio name="1~3年">1~3年</van-radio>
            <van-radio name="3年以上">3年以上</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">12. 是否进行过治疗，有何效果？</p>
      <van-field name="q12" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[11].value" direction="horizontal">
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
          <van-field v-model="otherQuestion.other2" placeholder="请描述治疗效果：" v-if="formData.questionsValues[11].value == '是'" />
        </template>
      </van-field>
      <van-button block type="info" native-type="submit">下一步</van-button>
    </van-form> -->

    <!-- <van-form @submit="secondStep" v-show="steps == 2">
      <h3>02 您是否患有以下疾病 </h3>
      <p class="question">1. 高血压/贫血</p>
      <van-field name="q8" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[7].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">2. 高血脂/高胆固醇</p>
      <van-field name="q9" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[8].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">3. 心血管病/中风</p>
      <van-field name="q10" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[9].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">4. 胸痛/胸闷</p>
      <van-field name="q11" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[10].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">5. 肝病/肾病</p>
      <van-field name="q12" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[11].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">6. 骨/关节损伤</p>
      <van-field name="q13" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[12].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">7. 糖尿病</p>
      <van-field name="q14" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[13].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">8. 肌肉疼痛/痉挛</p>
      <van-field name="q15" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[14].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">9. 呼吸困难/哮喘</p>
      <van-field name="q16" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[15].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">10. 背部/颈部疼痛</p>
      <van-field name="q17" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[16].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">11. 经常性头痛</p>
      <van-field name="q18" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[17].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="question">12. 耳石症</p>
      <van-field name="q19" label="" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-radio-group v-model="formData.questionsValues[18].value" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-button block type="info" native-type="submit">下一步</van-button>
    </van-form> -->

    <van-form @submit="thirdStep" v-show="steps == 2">
      <h3>01 基本信息 </h3>
      <van-field v-model="formData.name" name="姓名：" label="姓名：" :rules="[{ required: true, message: '请填写姓名' }]" class="blurBox" />
      <van-field name="性别：" readonly label="性别：" :value="genderName(formData.gender)" @click="showPicker = true" :rules="[{ required: true, message: '请填写密码' }]" class="blurBox" />
      <van-field name="出生日期：" readonly label="出生日期：" 
      :value="formData.birthday" 
      @click="birthdayCell = true" 
      :rules="[{ required: true, message: '请选择出生日期' }]" class="blurBox" />
      <!-- <van-field v-model="formData.mobile" name="电话：" label="电话：" :rules="telRules" class="blurBox" /> -->
      <h3 class="marTop80">02 预约就诊时间意向 </h3>
      <div><van-checkbox v-model="checked" shape="square" @change="changeDate">时间不确定，后期客服与您联系</van-checkbox></div>
      
      <div v-if="!checked">
        <van-field name="calendar" readonly :value="formData.assessDate" label="选择日期" @click="showCalendar = true" :rules="[{ required: true, message: '请选择日期' }]" class="blurBox" />
        <van-field name="datetimePicker" readonly :value="phase" label="选择时间" @click="showTimePicker = true" :rules="[{ required: true, message: '请选择时间' }]" class="blurBox"/>
      </div>
      <van-button block type="info" native-type="submit">下一步</van-button>
    </van-form>
    <!-- 生日 -->
    <van-popup v-model="birthdayCell" position="bottom">
      <van-datetime-picker type="date" title="选择年月日" :min-date="minBirthdayDate" :max-date="maxBirthdayDate" :value="defaultDate" :default-date="defaultDate" @confirm="birthdayChange" @cancel="birthdayCell = false" />
    </van-popup>
    <!-- 性别 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="columns" @confirm="changeGender" @cancel="showPicker = false" value-key="name" />
    </van-popup>
    <!-- 时间（上午，下午） -->
    <van-popup v-model="showTimePicker" position="bottom">
      <van-picker show-toolbar :columns="timeColumns" @confirm="changeTime" @cancel="showTimePicker = false" value-key="name" />
      <!-- <van-datetime-picker type="time" title="选择时间" :min-hour="9" :max-hour="18" @confirm="changeTime" @cancel="showTimePicker = false" /> -->
    </van-popup>
    <!-- 预约日期 -->
    <van-calendar v-model="showCalendar" @confirm="changeCalendar" :min-date="minDate" :max-date="maxDate" :show-confirm="false" :formatter="formatter" color="#24BBD3" />

  </div>
</template>
<script>
import { schedule } from "@/api/getData"
export default {
  name: "Questionnaire",
  data() {
    return {
      checked:false,
      minDate: new Date(2023,2, 1),
      maxDate: new Date(2023,2, 31),
      steps:2,
      birthdayCell: false,
      showPicker: false,
      showTimePicker: false,
      showCalendar: false,
      telRules: [{
        required: true,
        message: '手机号码不能为空',
        trigger: 'onBlur'
      }, {
        // 自定义校验规则
        validator: value => {
          return /^1[3456789]\d{9}$/
            .test(value)
        },
        message: '请输入正确格式的手机号码',
        trigger: 'onBlur'
      }],
      columns: [
        {
          name: '男',
          value: 1
        },
        {
          name: '女',
          value: 0
        }
      ],
      timeColumns: [
        {
          name: '上午',
          value: 0
        },
        {
          name: '下午',
          value: 1
        }
      ],
      // timeColumns: ['14:00-15:00', '15:30-16:30', '16:45-17:45'],
      defaultDate: new Date(2000, 0, 1),
      minBirthdayDate: new Date(1900, 0, 1),
      maxBirthdayDate: new Date(2020, 0, 1),
      phase:'',
      formData: {
        // mobile: '',
        name: '',
        gender: '',
        birthday: new Date(2000, 0, 1),
        assessDate: '',
        phase: null,//0-am;1-pm
        productCode: 'X199',
        questionsValues:null
        //  [
        //   {
        //     questionsId: 1,
        //     value: null
        //   },
        //   {
        //     questionsId: 2,
        //     value: null
        //   },
        //   {
        //     questionsId: 3,
        //     value: null
        //   },
        //   {
        //     questionsId: 4,
        //     value: null
        //   },
        //   {
        //     questionsId: 5,
        //     value: null
        //   },
        //   {
        //     questionsId: 6,
        //     value: null
        //   },
        //   {
        //     questionsId: 7,
        //     value: null
        //   },
       
        //   {
        //     questionsId: 20,
        //     value: null
        //   },
        //   {
        //     questionsId: 21,
        //     value: null
        //   },
        //   {
        //     questionsId: 22,
        //     value: []
        //   },
        //   {
        //     questionsId: 23,
        //     value: null
        //   },
        //   {
        //     questionsId: 24,
        //     value: null
        //   }
        // ]
      },
      otherQuestion: {
        other1: '',
      }
    }
  },
  methods: {
    changeDate(){
      console.log(this.checked)
      if(this.checked){
        this.formData.assessDate = '1900-01-01'
        this.formData.phase = -1
        this.phase = -1
        
      }else{
        this.formData.assessDate = ''
        this.formData.phase = null
        this.phase = null
        
      }
    },
    arrToStr(arr) {
      return arr.join(",")
    },
    changeCalendar(date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) month = '0' + month // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate // 如果日是个位数，在前面补0
      this.formData.assessDate = `${year}-${month}-${strDate}`;
      this.showCalendar = false;
    },
    changeTime(val) {
      console.log(val, 2222)
      console.log(this.formData.phase)
      this.showTimePicker = false
      this.formData.phase = val.value
      this.phase = val.name
      console.log(this.formData.phase)
    },
    timeName(val) {
      if (val == 0) {
        return '上午'
      } else if (val == 1) {
        return '下午'
      } else {
        return ''
      }
    },
    genderName(val) {
      if (val == 0) {
        return '女'
      } else if(val == 1){
        return '男'
      }
    },
    changeGender(value) {
      console.log(value)
      this.showPicker = false
      this.formData.gender = value.value
    },
    formatDate(date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) month = '0' + month // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate // 如果日是个位数，在前面补0
      return `${year}-${month}-${strDate}`;
    },
    birthdayChange(date) {
      console.log(date)
      this.formData.birthday = this.formatDate(date);
      this.birthdayCell = false
    },
    firstStep(values) {
      this.steps = 2
      document.documentElement.scrollTop = document.body.scrollTop = 0
    },
    // secondStep(values) {
    //   this.steps = 3
    // },
    thirdStep(values) {
      // if (this.formData.questionsValues[8].value == '有' && this.otherQuestion.other1) {
      //   this.formData.questionsValues[8].value = this.otherQuestion.other1
      // }
      // if (this.formData.questionsValues[11].value == '是' && this.otherQuestion.other2) {
      //   this.formData.questionsValues[11].value = this.otherQuestion.other2
      // }
      // this.formData.questionsValues[9].value = this.arrToStr(this.formData.questionsValues[9].value)
      console.log(this.formData,333)
      schedule(this.formData).then(res => {
        this.$toast.success('提交成功');
        // this.$router.push('/bookingStatus')
        if (!res.data.pay) {
          this.$router.push({
            path: "/wechatPay",
            query: {
              productCode: res.data.productCode,
              sn: res.data.sn,
              scheduleId: res.data.id
            }
          })
        } else {
          this.$router.push({
            path: "/bookingStatus",
            query: {
              scheduleId: res.data.id
            }
          })
        }
      })

      // this.$router.push('/bookingStatus')
    },
    formatter(day) {
      // || day.date.getDay() === 6
      if (day.date.getDay() === 0 || new Date() - day.date > 86400000) {
        day.type = 'disabled'
      }
      return day
    },
  },
  created() {
    // this.formData.mobile = this.vUserInfo.mobile
    // this.formData.name = this.vUserInfo.name
    this.formData.gender = this.vUserInfo.gender
    this.formData.birthday = this.vUserInfo.birthday
  },
  mounted() {
    // console.log(this.vUserInfo.mobile)
  }
}
</script>

<style lang="scss" scoped>
.questionnaire {
  .steps {
    height: 0.06rem;
    background: #24bbd3;
    width: 33.3%;
  }
  .van-form {
    padding: 0 0.16rem;
    h3 {
      font-size: 0.18rem;
      line-height: 1;
      font-weight: 500;
      margin: 0.36rem 0;
    }
    .question {
      font-size: 0.18rem;
      line-height: 0.32rem;
      font-weight: bold;
      margin-top: 0.4rem;
      margin-bottom: 0.2rem;
    }
    .van-cell {
      background: none;
      padding: 0;
      .van-cell__value {
        .van-field__body {
        }
      }
    }
    .blurBox {
      font-size: 0.14rem;
      min-height: 0.4rem;
      line-height: 0.4rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 0.4rem;
      :deep(.van-field__label) {
        line-height: 0.4rem;
      }
      :deep(.van-cell__value) {
        line-height: 0.4rem;
        .van-field__body {
          line-height: 0.4rem;
        }
      }
    }
    :deep(.van-field__control--custom) {
      display: block !important;
    }
    .van-cell::after {
      border: none;
    }
    .van-radio-group--horizontal {
      width: 100%;
      display: block;
      .van-radio {
        margin: 0;
        background: yellow;
        height: 0.62rem;
        border-radius: 4px;
        background: rgba(36, 187, 211, 0.08);
        padding-left: 0.24rem;
        box-sizing: border-box;
        font-size: 0.14rem;
        font-weight: bold;
        margin-bottom: 0.08rem;
        :deep(.van-radio__icon--checked .van-icon) {
          background-color: #24bbd3 !important;
          border-color: #24bbd3 !important;
        }
      }
    }
    :deep(.van-field__control--custom) {
      .van-field__control {
        height: 0.4rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    .van-checkbox-group--horizontal {
      display: block;
      .van-checkbox {
        margin: 0;
        background: yellow;
        height: 0.62rem;
        border-radius: 4px;
        background: rgba(36, 187, 211, 0.08);
        padding-left: 0.24rem;
        box-sizing: border-box;
        font-size: 0.14rem;
        font-weight: bold;
        margin-bottom: 0.08rem;
        :deep(.van-checkbox__icon--checked .van-icon) {
          background-color: #24bbd3 !important;
          border-color: #24bbd3 !important;
        }
      }
    }
    .van-button {
      margin-top: 0.5rem;
      margin-bottom: 0.3rem;
    }
  }
}
</style>